* {
	box-sizing: border-box;
}

*:focus {
	outline: none;
}

body {
	font-family: 'Roboto', sans-serif;
	font-size: 0.625em;
	font-weight: 300;
	line-height: 1.4;
	margin: 0;
	padding: 0;
	letter-spacing: 0.01em;
	line-height: 1.62;
	min-width: 320px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $headColor;
	font-family: 'Sarabun', sans-serif;
	font-weight: 600;
	letter-spacing: normal;
	margin: 0;
	padding-top: 1.62em;
}

h1 {
	font-size: 4.3em;
	padding-top: 0.38em;
    margin-left: -0.04em;
}

h2 {
	font-size: 3.1em;
	margin-left: -0.06em;
}

h3 {
	font-size: 2.2em;
	margin-left: -0.06em;
}2

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	text-decoration: none;
}

strong {
	font-weight: 500;
}

p,
li,
table td {
	font-size: 1.6em;
	color: $textColor;
	margin-bottom: 0;
	margin-top: 1em;
}

ul {
	margin-left: 0.50em;
}

ol {
	margin-left: 0.42em;
}

a {
	display: inline;
	color: $linkColor;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

sup {
	font-size: .4em;
	position: relative;
	top: -.3em;
	font-weight: 700;
	z-index: 0;
}

table {
	margin-top: 1em;
	border: 1px solid #CCC;
    border-collapse: collapse;
	width: 100%;
}

td {
	padding: 0.39em 0.62em;
	border: 1px solid #CCC;
}

.PageContainer {
	display: flex;
	margin: 0 auto;
	max-width: 1440px;
	min-height: calc(100vh - $footerHeight);
	padding-top: $headerHeight;
}
